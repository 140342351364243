<template>
  <q-card class="full-width" :style="{ maxWidth: '600px' }">
    <q-card-section>
      <h3 class="text-h5 q-mb-lg q-mt-none">
        {{ isCreateMode ? 'Add Requisite' : 'Update Requisite' }}
      </h3>

      <q-form @submit.prevent="onSubmitForm">
        <q-card class="q-mb-lg">
          <q-card-section>
            <h4 class="text-h6 q-mt-none q-mb-none flex justify-between">
              Payload

              <q-btn
                round
                dense
                flat
                color="primary"
                icon="mdi-plus-circle"
                @click="addPayloadItem"
              />
            </h4>

            <q-card
              v-for="(item, itemIndex) in form.payload"
              :key="itemIndex"
              class="q-mt-lg"
            >
              <q-card-section>
                <q-btn
                  round
                  dense
                  flat
                  color="negative"
                  icon="mdi-minus-circle"
                  class="flex q-ml-auto"
                  @click="deletePayloadItem(itemIndex)"
                />

                <q-input
                  v-model.trim="item.key"
                  :rules="[fieldRules.required, fieldRules.key]"
                  outlined
                  label="Key"
                  :readonly="!form.editable"
                  class="q-mb-md q-mt-md"
                />

                <q-input
                  v-model.trim="item.value"
                  :rules="[fieldRules.required]"
                  :readonly="!form.editable"
                  outlined
                  label="Value"
                />
              </q-card-section>
            </q-card>
          </q-card-section>
        </q-card>

        <q-card class="q-mb-lg">
          <q-card-section>
            <h4 class="text-h6 q-mt-none q-mb-none flex justify-between">
              Properties

              <q-btn
                round
                dense
                flat
                color="primary"
                icon="mdi-plus-circle"
                @click="addPropertyItem"
              />
            </h4>

            <q-card
              v-for="(item, itemIndex) in form.properties"
              :key="itemIndex"
              class="q-mt-lg"
            >
              <q-card-section>
                <q-btn
                  round
                  dense
                  flat
                  color="negative"
                  icon="mdi-minus-circle"
                  class="flex q-ml-auto"
                  @click="deletePropertyItem(itemIndex)"
                />

                <q-input
                  v-model.trim="item.key"
                  :rules="[fieldRules.required, fieldRules.key]"
                  outlined
                  label="Key"
                  class="q-mb-md q-mt-md"
                />

                <q-input
                  v-model.trim="item.value"
                  :rules="[fieldRules.required]"
                  outlined
                  label="Value"
                />
              </q-card-section>
            </q-card>
          </q-card-section>
        </q-card>

        <q-toggle
          v-if="isCreateMode"
          v-model="form.enable"
          dense
          color="green"
          label="Enable"
        />

        <div class="row q-mt-md">
          <section class="col-6">
            <div class="q-pr-sm">
              <q-btn
                :disable="form.loading"
                no-caps
                unelevated
                outline
                type="button"
                color="red"
                label="Cancel"
                class="full-width"
                @click="onCancel"
              />
            </div>
          </section>

          <section class="col-6">
            <div class="q-pl-sm">
              <q-btn
                :loading="form.loading"
                :disable="disabledSubmitForm"
                no-caps
                unelevated
                type="submit"
                color="primary"
                :label="isCreateMode ? 'Create' : 'Save'"
                class="full-width"
              />
            </div>
          </section>
        </div>
      </q-form>
    </q-card-section>
  </q-card>
</template>

<script>
import { accountController } from '@/shared/api';
import { cloneStructured } from '@/shared/utils';

export default {
  name: 'RequisiteForm',
  props: {
    accountId: {
      type: Number,
      required: true,
    },
    initialData: {
      type: Object,
    },
  },
  data() {
    const { payload, properties, enable, id, editable } = this.initialData || {
      id: 0,
      payload: [],
      properties: [],
      enable: true,
      editable: true,
    };

    return {
      form: {
        loading: false,
        id,
        payload: cloneStructured(payload),
        properties: cloneStructured(properties),
        enable,
        editable,
      },
    };
  },
  computed: {
    disabledSubmitForm() {
      return false;
    },
    fieldRules() {
      return {
        required: (value) =>
          !!value?.toString().trim().length || 'Please type value',

        key: (value) =>
          (value && /^[a-z_:]+$/gi.test(value)) ||
          'The following characters are allowed a-zA-Z_:',

        onlyPositiveNumber: (value) =>
          +value >= 0 || 'Please type only positive number',
      };
    },
    isCreateMode() {
      return !this.form.id;
    },
  },
  methods: {
    onCancel() {
      this.$emit('cancel');
    },
    addPayloadItem() {
      this.form.payload.push({
        key: '',
        value: '',
      });
    },
    deletePayloadItem(index) {
      this.form.payload.splice(index, 1);
    },
    addPropertyItem() {
      this.form.properties.push({
        key: '',
        value: '',
      });
    },
    deletePropertyItem(index) {
      this.form.properties.splice(index, 1);
    },
    async onSubmitForm() {
      if (this.form.loading) return;

      this.form.loading = true;

      const { id, properties, payload, enable } = this.form;

      try {
        if (this.isCreateMode) {
          await accountController.createRefillAccountRequisite({
            accountId: this.accountId,
            properties,
            payload,
            enable,
          });

          this.$emit('created');
        } else {
          await accountController.updateRefillAccountRequisite({
            id,
            accountId: this.accountId,
            properties,
            payload,
          });

          this.$emit('updated');
        }

        this.form.loading = false;
      } catch (error) {
        this.form.loading = false;
      }
    },
  },
};
</script>
